import React from 'react';
import Slider, { Settings } from 'react-slick';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Theme } from '@theme/base';
import { mq } from '@styles/breakpoints';
import { CarouselTile as CarouselTileModel } from '@model/content';
import { TestId } from '@components/test-ids';
import { Arrow } from '@components/common';
import { HomeCarouselTile } from './HomeCarouselTile';

/* ***************** *
 *       Types       *
 * ***************** */
export interface HomeCarouselProps {
  tiles: Array<CarouselTileModel>;
  imagesOnly?: boolean;
  fillScreen?: boolean;
  theme?: Theme;
}
//
/* ***************** *
 *       Styles      *
 * ***************** */
interface ContainerProps {
  fillScreen?: boolean;
}
const Container: any = styled.div(({ fillScreen }: ContainerProps) => ({
  ...(!fillScreen && {
    ['.slick-slide']: {
      height: '130vw',
      [mq.small]: {
        height: '50vw'
      },
      [mq.large]: {
        height: '33.33vw'
      }
    }
  })
}));

const Dots = () => (dots) => (
  <div style={{ bottom: 32 }}>
    <ul style={{ margin: 0, padding: 0 }}> {dots} </ul>
  </div>
);

/* *********************** *
 *    HomeCarouselComponent    *
 * *********************** */

export const HomeCarouselComponent = ({ tiles, theme, imagesOnly, fillScreen }: HomeCarouselProps) => {
  const renderAllTiles = (tiles: Array<CarouselTileModel>) =>
    (tiles || []).map((tile) => (
      <HomeCarouselTile key={tile.heading} {...tile} imagesOnly={imagesOnly} fillScreen={fillScreen} />
    ));

  const settings: Settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 6000,
    autoplay: true,
    nextArrow: <Arrow theme={theme} next inset />,
    prevArrow: <Arrow theme={theme} inset />,
    appendDots: Dots()
  };

  return (
    <Container data-testid={TestId.homepage.carousel} fillScreen={fillScreen}>
      <Slider {...settings}>{renderAllTiles(tiles)}</Slider>
    </Container>
  );
};

export const HomeCarousel = withTheme(HomeCarouselComponent);
